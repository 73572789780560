import React, { cloneElement } from 'react';

import { GridLayoutItem } from 'components/grid/GridLayout';

import s from './StaffItem.module.scss';

type Props = {
  image: React.ReactElement;
  name: string;
  title: string;
  tel?: string;
  email: string;
};

export const StaffItem = (props: Props) => {
  const { image, name, title, tel, email } = props;

  return (
    <GridLayoutItem
      columns={{ desktop: 3, tablet: 4, mobile: 6 }}
      className={s.staffItem}
    >
      <div className={s.staffItem__imageWrap}>
        {cloneElement(image, { className: s.staffItem__image })}
      </div>
      <div className={s.staffItem__name}>{name}</div>
      <div className={s.staffItem__title}>{title}</div>
      <div className={s.staffItem__tel}>
        <a className={s.staffItem__link} href={`tel:+354${tel}`}>
          {tel}
        </a>
      </div>
      <div className={s.staffItem__email}>
        <a className={s.staffItem__link} href={`mailto:${email.replace(/\u00AD/g, '')}`}>
          {email}
        </a>
      </div>
    </GridLayoutItem>
  );
};
