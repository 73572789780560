import {
  EducationSlicesAccordion,
  NewsSlicesAccordion,
  PageSlicesAccordion,
  PageSlicesAccordionDefaultItems,
  Power_StationSlicesAccordion,
  Power_StationSlicesAccordionDefaultItems,
} from 'prismic-types';

import { Accordion } from 'components/accordion/Accordion';
import { AccordionItem } from 'components/accordion/AccordionItem';
import { asText, RichText } from 'components/rich-text/RichText';

type AccordionItem =
  | PageSlicesAccordionDefaultItems
  | Power_StationSlicesAccordionDefaultItems;

type Props = {
  title?: string;
  items: Array<AccordionItem>;
};

export type AccordionSlice =
  | Power_StationSlicesAccordion
  | PageSlicesAccordion
  | NewsSlicesAccordion
  | EducationSlicesAccordion;

export function AccordionSlice({ items, title }: Props) {
  return (
    <Accordion title={title}>
      {items
        .filter((item) => item.title && item.content)
        .map((item, i) => (
          <AccordionItem key={i} title={asText(item.title)}>
            <RichText>{item.content}</RichText>
          </AccordionItem>
        ))}
    </Accordion>
  );
}

export function prismicSliceToAccordion(s: AccordionSlice) {
  const items = (s.variation?.items ?? null) as Array<PageSlicesAccordionDefaultItems>;

  if (!items.length) {
    return null;
  }

  return (
    <AccordionSlice
      items={items}
      title={s.variation?.primary?.title ? asText(s.variation.primary.title) : undefined}
    />
  );
}
