import { DateTime } from 'components/date/Date';
import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout';
import { H2 } from 'components/heading/Heading';

import { HeadingTags } from 'types/html-types';

import s from './Text.module.scss';

type TextProps = {
  title?: string;
  date?: string;
  headingType?: HeadingTags;
  children: React.ReactElement;
};

export const Text = ({ title, date, headingType = 'h2', children }: TextProps) => {
  return (
    <GridLayout className={s.text}>
      <GridLayoutItem columns={{ desktop: 7, tablet: 9 }} offset={{ desktop: 2 }}>
        {date && <DateTime dateString={date} />}
        {title && (
          <H2 type={headingType} className={s.text__title}>
            {title}
          </H2>
        )}
        <div className={s.text__text}>{children}</div>
      </GridLayoutItem>
    </GridLayout>
  );
};
