import React from 'react';

import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout';
import { H2 } from 'components/heading/Heading';

import { HeadingTags } from 'types/html-types';

import s from './Staff.module.scss';

type Props = {
  title?: string;
  description?: string;
  children: React.ReactNode;
  headingType?: HeadingTags;
};

export const Staff = (props: Props) => {
  const { title, description, children, headingType = 'h2' } = props;

  if (!children) {
    return null;
  }

  return (
    <div className={s.staff}>
      <GridLayout>
        <GridLayoutItem offset={{ desktop: 1 }}>
          <H2 type={headingType} as="h2" className={s.staff__title}>
            {title}
          </H2>
          <div className={s.staff__description}>{description}</div>
        </GridLayoutItem>
      </GridLayout>
      <GridLayout className={s.staff__children}>{children}</GridLayout>
    </div>
  );
};
