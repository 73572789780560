import { CellDataHyperlink, SheetData } from '@hugsmidjan_is/sheet-api';

import { DataTable, TableDataProps } from 'components/data-table/DataTable';
import { DataTableRow } from 'components/data-table/DataTableRow';

type Props = {
  title: string;
  wide?: boolean;
  data: SheetData | null;
  highlightColumns?: Array<number>;
  footnote: string;
};

export const GSheet = ({ title, data, highlightColumns = [], wide, footnote }: Props) => {
  if (!data || (data.grid ?? []).length < 2) {
    return null;
  }

  const headerData: Array<TableDataProps> = data.grid[0].map((row, i) => {
    const highlight = highlightColumns.indexOf(i + 1) >= 0;

    return { value: row.text, highlight };
  });

  const cells: Array<Array<TableDataProps>> = data.grid.slice(1).map((row) => {
    const cell: Array<TableDataProps> = row.map((field, j) => {
      const value = field.text ?? '';
      const highlight = highlightColumns.indexOf(j + 1) >= 0;
      const title = (headerData[j].value as string) ?? undefined;
      const data: TableDataProps = { title, value, highlight };

      if ((field as CellDataHyperlink).url) {
        data.url = (field as CellDataHyperlink).url;
      }

      return data;
    });
    return cell;
  });

  return (
    <DataTable
      title={title}
      wide={wide}
      header={headerData.length > 0 && <DataTableRow isHeader cells={headerData} />}
      footnote={footnote}
    >
      {cells.map((item, i) => (
        <DataTableRow
          key={i}
          cells={item}
          titles={headerData.map((row) => row.value as string)}
        />
      ))}
    </DataTable>
  );
};
export default GSheet;
