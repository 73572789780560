import React from 'react';
import sortIsl from '@hugsmidjan/qj/sortIsl';
import { PrismicRichText } from 'prismic/types/richtext';
import {
  EducationSlicesStaff_List,
  NewsSlicesStaff_List,
  PageSlicesStaff_List,
  PageSlicesStaff_ListDefaultItems,
} from 'prismic-types';

import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture';
import { asText } from 'components/rich-text/RichText';
import { Staff } from 'components/staff/Staff';
import { StaffItem } from 'components/staff/StaffItem';

import { titleHeadingTag } from 'utils/pageTitleFromSlices';

type Props = {
  title?: PrismicRichText;
  description?: PrismicRichText;
  items?: Array<PageSlicesStaff_ListDefaultItems>;
};

export const StaffListSlice = (props: Props) => {
  const { title, description, items } = props;

  if (!items) {
    return null;
  }

  return (
    <Staff
      title={asText(title)}
      headingType={titleHeadingTag(title)}
      description={asText(description)}
    >
      {items.map((item, i) => (
        <StaffItem
          key={i}
          image={<RegularOrCroppableImage image={item.image} />}
          name={asText(item.name)}
          title={asText(item.info)}
          tel={item.tel ? asText(item.tel.toString()) : undefined}
          email={asText(item.email)}
        />
      ))}
    </Staff>
  );
};

export const prismicSliceToStaffList = (
  s: PageSlicesStaff_List | NewsSlicesStaff_List | EducationSlicesStaff_List
) => {
  const title = (s.variation?.primary?.title ?? null) as PrismicRichText;
  const description = (s.variation?.primary?.description ?? null) as PrismicRichText;
  const items = (s.variation?.items ?? []) as Array<PageSlicesStaff_ListDefaultItems>;
  const orderedItems = sortIsl(items, {
    getProp: (item: PageSlicesStaff_ListDefaultItems) => {
      const name = asText(item.name ?? null);
      if (!name) {
        return 'ö';
      }

      return name;
    },
  });

  return <StaffListSlice title={title} description={description} items={orderedItems} />;
};
