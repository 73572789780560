import classnames from 'classnames/bind';

import { Link } from 'components/link/Link';

import { TableDataProps } from './DataTable';

import s from './DataTable.module.scss';

const c = classnames.bind(s);

type IProps = {
  isHeader?: boolean;
  isFooter?: boolean;
  cells: Array<TableDataProps>;
  titles?: Array<string | undefined>; // titles to be displayed in mobile
};

export const DataTableRow = ({ cells, titles, isHeader, isFooter }: IProps) => {
  if (!cells.length) {
    return null;
  }

  const renderCells = () => {
    return cells.map((item, i) => {
      const TableCell = isHeader || (isFooter && i === 0) ? 'th' : 'td';
      return (
        <TableCell
          className={c(s.dataTable__cell, {
            highlight: item.highlight,
          })}
          key={i}
          title={titles ? titles[i] : undefined}
        >
          {item.url && <Link to={item.url}>{item.value}</Link>}
          {!item.url && item.value}
        </TableCell>
      );
    });
  };

  return <tr>{renderCells()}</tr>;
};
