import { Children } from 'react'
import classnames from 'classnames/bind'

import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout'
import { H2 } from 'components/heading/Heading'

import s from './DataTable.module.scss'

const c = classnames.bind(s)

export type TableDataProps = {
  title?: string
  highlight?: boolean
  value: string | React.ReactNode | number
  url?: string
  footnote?: string
  wide?: boolean
}

type Props = {
  title?: string
  children: React.ReactNode
  header?: React.ReactElement | false
  footer?: React.ReactElement | false
  footnote: string
  wide?: boolean
}

export const DataTable = ({
  children,
  title,
  header,
  footer,
  footnote,
  wide,
}: Props) => {
  const childArray = Children.toArray(children).filter(Boolean)

  const notWideTable = 8
  const wideTable = 10

  const columnsForTable = wide ? wideTable : notWideTable

  return (
    <div className={s.dataTable}>
      <GridLayout className={s.dataTable__inner}>
        {title && (
          <GridLayoutItem
            offset={{ tablet: 1, desktop: 2 }}
            columns={{ tablet: wideTable, desktop: notWideTable }}
            className={s.dataTable__title}
          >
            <H2 className={s.dataTable__heading}>{title}</H2>
          </GridLayoutItem>
        )}
        <GridLayoutItem
          offset={{ desktop: wide ? 1 : 2 }}
          columns={{
            tablet: 12,
            desktop: columnsForTable,
            wide: columnsForTable,
          }}
          className={s.dataTable__content}
        >
          <table
            style={{ width: '100%' }}
            className={c(s.dataTable__table, {
              fixedWidth: childArray.length > 5,
            })}
          >
            {header && <thead>{header}</thead>}
            <tbody>{children}</tbody>
            {footer && <tfoot>{footer}</tfoot>}
          </table>
        </GridLayoutItem>
        {footnote && (
          <GridLayoutItem
            offset={{ tablet: 1, desktop: 2 }}
            columns={{ tablet: wideTable, desktop: notWideTable }}
            className={s.dataTable__footnote}
          >
            {footnote}
          </GridLayoutItem>
        )}
      </GridLayout>
    </div>
  )
}
