import { Children, cloneElement, useState } from 'react'

import s from './Accordion.module.scss'

type IProps = {
  children: React.ReactNode
  title?: string
}

export const Accordion = ({ children, title }: IProps) => {
  const [activeItem, setActiveItem] = useState<number | undefined>()
  const childArray = Children.toArray(children).filter(Boolean)

  if (!childArray.length) {
    return null
  }

  return (
    <div className={s.accordion}>
      <div className={s.accordion__inner}>
        {title && <h2 className={s.accordion__title}>{title}</h2>}
        <ul className={s.accordion__list}>
          {Children.toArray(children)
            .filter(Boolean)
            .map((child, i) =>
              cloneElement(child as React.ReactElement, {
                active: i === activeItem,
                handleOpen: () => setActiveItem(i),
                handleClose: () => setActiveItem(undefined),
                key: i,
              })
            )}
        </ul>
      </div>
    </div>
  )
}
