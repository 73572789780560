import React from 'react';
import { PrismicRichText } from 'prismic/types/richtext';
import {
  EducationSlicesText,
  NewsSlicesText,
  PageSlicesText,
  Power_StationSlicesText,
} from 'prismic-types';

import { asText, RichText } from 'components/rich-text/RichText';
import { Text } from 'components/text/Text';

import { titleHeadingTag } from 'utils/pageTitleFromSlices';

type Props = {
  title?: PrismicRichText;
  date?: string;
  text?: PrismicRichText;
  richTextClassname?: string;
};

export type TextSlice =
  | Power_StationSlicesText
  | PageSlicesText
  | NewsSlicesText
  | EducationSlicesText;

export const Textlice = (props: Props) => {
  const { title, date, text, richTextClassname } = props;

  if (!title && !text) {
    return null;
  }

  return (
    <Text title={asText(title)} headingType={titleHeadingTag(title)} date={date}>
      <RichText className={richTextClassname}>{text}</RichText>
    </Text>
  );
};

export const prismicSliceToText = (
  s: TextSlice,
  richTextClassname?: string,
  date?: string
) => {
  const title = (s.variation?.primary?.title ?? null) as PrismicRichText;
  const text = (s.variation?.primary?.text ?? null) as PrismicRichText;

  return (
    <Textlice
      title={title}
      text={text}
      richTextClassname={richTextClassname}
      date={date}
    />
  );
};
