import { SheetData } from '@hugsmidjan_is/sheet-api'
import { PrismicRichText } from 'prismic/types/richtext'
import {
  EducationSlicesGoogle_Sheet_Table,
  NewsSlicesGoogle_Sheet_Table,
  PageSlicesGoogle_Sheet_Table,
  Power_StationSlicesGoogle_Sheet_Table,
} from 'prismic-types'

import { asText } from 'components/rich-text/RichText'
import { GSheet } from 'containers/gsheet/GSheet'

type Props = {
  title: PrismicRichText
  footnote?: PrismicRichText
  data?: SheetData | null
  wide?: boolean
}

export type GoogleSheetTableSlice =
  | Power_StationSlicesGoogle_Sheet_Table
  | NewsSlicesGoogle_Sheet_Table
  | PageSlicesGoogle_Sheet_Table
  | EducationSlicesGoogle_Sheet_Table

export function GoogleSheetTableSlice({ title, footnote, data, wide }: Props) {
  if (!data) {
    return null
  }

  return (
    <GSheet
      title={asText(title)}
      footnote={asText(footnote)}
      data={data}
      wide={wide}
    />
  )
}

export function prismicSliceToGoogleSheetTableSlice(s: GoogleSheetTableSlice) {
  let data = null

  try {
    data = JSON.parse(s.variation?.primary?.data)
  } catch (e) {
    console.warn(
      'Unable to parse google sheet data',
      s.variation?.primary?.linkToSheet
    )
  }

  return (
    <GoogleSheetTableSlice
      title={(s.variation?.primary?.title ?? null) as PrismicRichText}
      data={data as SheetData | null}
      footnote={(s.variation?.primary?.footnote ?? null) as PrismicRichText}
    />
  )
}
