import { Children, useCallback } from 'react'
import classnames from 'classnames/bind'
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'

import s from './AccordionItem.module.scss'
import { AccordionItemChevron } from './AccordionItemChevron'

const c = classnames.bind(s)

type IProps = {
  children: React.ReactNode
  title?: string
  active?: boolean
  setActive?: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen?: () => void
  handleClose?: () => void
}

export const AccordionItem = ({
  children,
  title,
  active,
  handleOpen,
  handleClose,
}: IProps) => {
  const childArray = Children.toArray(children)
  const filteredChildren = childArray.filter(Boolean)
  const toggleItem = useCallback(() => {
    if (!handleClose || !handleOpen) {
      return
    }
    if (active) {
      handleClose()
    } else {
      handleOpen()
    }
  }, [active, handleClose, handleOpen])

  if (!children || filteredChildren.length === 0) {
    return null
  }

  return (
    <li className={c(s.accordionItem, { active })}>
      <div className={s.accordionItem__inner}>
        <div className={s.accordionItem__content}>
          <button className={s.accordionItem__button} onClick={toggleItem}>
            <h3 className={s.accordionItem__title}>{title}</h3>

            <AccordionItemChevron active={active} />
          </button>
          <LazyMotion features={domAnimation}>
            <AnimatePresence initial={false}>
              {active && (
                <m.div
                  className={s.accordionItem__textWrap}
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.4, ease: [0.33, 1, 0.68, 1] }}
                >
                  <div className={s.accordionItem__text}>{children}</div>
                </m.div>
              )}
            </AnimatePresence>
          </LazyMotion>
        </div>
      </div>
    </li>
  )
}
