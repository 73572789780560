import { motion } from 'framer-motion'
import Chevron from 'assets/svg/chevron.svg'

import s from './AccordionItem.module.scss'

type IProps = {
  active?: boolean
}

export const AccordionItemChevron = ({ active }: IProps) => {
  return (
    <span className={s.accordionItem__indicator}>
      <motion.div
        className={s.accordionItem__svg}
        animate={{ rotate: active ? -180 : -90 }}
        transition={{ duration: 0.15 }}
      >
        <Chevron />
      </motion.div>
    </span>
  )
}
